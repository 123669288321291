import React from "react"
import {Layout} from "../components/Layout/layout";
import {PageTitle} from "../components/PageTitle";
import {Alert, Container, Row} from "reactstrap";
import {graphql} from "gatsby";
import {Panel, Text} from "../components/Panel";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default (props) => (
    <React.Fragment>
        <Layout pageName="choirs">
            <PageTitle title="Choirs"/>
            <Container>
                <Row className="margin-bottom-20">
                    {props.data.choirs.edges.map((o, idx) => (
                        <Panel key={idx} title={o.node.frontmatter.title} subtitle={o.node.frontmatter.subtitle}
                               image={o.node.frontmatter.image} width={o.node.frontmatter.width}>
                            <MDXRenderer>{o.node.body}</MDXRenderer>
                        </Panel>
                    ))}
                </Row>
                <Alert>
                    All choirs will have additional rehearsals to help prepare for the concert. Please check out the  dates and times on the registration page.
                </Alert>
            </Container>
        </Layout>
    </React.Fragment>
);

export const query = graphql`
    query {
        choirs: allMdx(
            sort: {
                fields: [frontmatter___sort]
            },
            filter: {
                fileAbsolutePath: {
                    regex: "/choirs/"
                },
                frontmatter: {
                    enabled: {
                        eq: true
                    }
                }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        subtitle
                        image
                        enabled
                        sort
                        width
                    }
                    body
                }
            }
        }
    }
`;
